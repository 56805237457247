import { jsx as _jsx } from "react/jsx-runtime";
import { createBrowserRouter } from 'react-router-dom';
import deepmerge from 'deepmerge';
import { Suspense } from 'react';
import { ErrorComponent, RouterErrorElement } from '@shared/ui/errors/Error';
import OverviewWalletDetails from '@src/pages/OverviewWalletDetails';
import PortfoliosTab from '@widgets/PortfoliosTab/ui';
import FollowersTab from '@widgets/FollowersTab';
import FollowingTab from '@widgets/FollowingTab';
import CopiersTab from '@widgets/CopiersTab';
import ActivityTab from '@widgets/ActivityTab';
import GeneralPortfolioInformation from '@widgets/GeneralPortfolioInformation';
import Profile from '@pages/profile';
import MyProfile from '@pages/my-profile';
import { Auth } from '@pages/auth';
import Marketplace from '@pages/marketplace';
import { Settings } from '@pages/settings';
import Subscription from '@pages/settings-subscription';
import PersonalInformation from '@pages/settings-personal-information';
import Home from '@pages/Home';
import ConfirmEmail from '@pages/ConfirmEmail';
import ChangePassword from '@pages/ChangePassword';
import MarketplaceList from '@pages/marketplace-list';
import CurrentPositions from '@components/portfolio/tabs/CurrentPositions';
import PositionsHistory from '@components/portfolio/tabs/PositionsHistory';
import PlatformBalance from '@pages/PlatformBalance';
import Overview from '@pages/Overview';
import Support from '@pages/Support';
import DevComponents from '@pages/DevComponents';
import { IsDefined } from '@utils/js-ts';
import PortfolioCopiers from '@src/components/portfolio/tabs/PortfolioCopiers';
import routes from './routes';
import { RouteNames } from './types';
import RouteAuthCheckWrapper from '../helpers/RouteAuthCheckWrapper';
import UnathorizedLayout from '../layouts/UnauthorizedLayout/UnathorizedLayout';
import AuthorizedLayout from '../layouts/AuthorizedLayout/AuthorizedLayout';
const getErrorElementByRoute = (routeName) => {
    let element;
    switch (routeName) {
        default:
            element = _jsx(RouterErrorElement, {});
    }
    return element;
};
const getElementByRoute = (routeName) => {
    let element;
    switch (routeName) {
        case (RouteNames.PROFILE):
            element = _jsx(Profile, {});
            break;
        case (RouteNames.MY_PROFILE):
            element = _jsx(MyProfile, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS):
        case (RouteNames.PROFILE_PORTFOLIOS):
            element = _jsx(PortfoliosTab, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_INFORMATION):
            element = _jsx(GeneralPortfolioInformation, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_CURRENT_POSITIONS):
            element = _jsx(CurrentPositions, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_HISTORY):
            element = _jsx(PositionsHistory, {});
            break;
        case (RouteNames.MY_PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS):
        case (RouteNames.PROFILE_PORTFOLIOS_PORTFOLIO_COPIERS):
            element = _jsx(PortfolioCopiers, {});
            break;
        case (RouteNames.MY_PROFILE_FOLLOWERS):
        case (RouteNames.PROFILE_FOLLOWERS):
            element = _jsx(FollowersTab, {});
            break;
        case (RouteNames.MY_PROFILE_FOLLOWINGS):
        case (RouteNames.PROFILE_FOLLOWINGS):
            element = _jsx(FollowingTab, {});
            break;
        case (RouteNames.MY_PROFILE_ACTIVITY):
        case (RouteNames.PROFILE_ACTIVITY):
            element = _jsx(ActivityTab, {});
            break;
        case (RouteNames.MY_PROFILE_COPIERS):
        case (RouteNames.PROFILE_COPIERS):
            element = _jsx(CopiersTab, {});
            break;
        case (RouteNames.MARKETPLACE):
            element = _jsx(Marketplace, {});
            break;
        case (RouteNames.MARKETPLACE_LIST):
            element = _jsx(MarketplaceList, {});
            break;
        case (RouteNames.SETTINGS):
            element = _jsx(Settings, {});
            break;
        case (RouteNames.SETTINGS_PERSONAL_INFORMATION):
            element = _jsx(PersonalInformation, {});
            break;
        case (RouteNames.SETTINGS_SUBSCRIPTION):
            element = _jsx(Subscription, {});
            break;
        case (RouteNames.AUTH):
            element = _jsx(Auth, {});
            break;
        case (RouteNames.CONFIRM_EMAIL):
            element = _jsx(ConfirmEmail, {});
            break;
        case (RouteNames.CHANGE_PASSWORD):
            element = _jsx(ChangePassword, {});
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_GENERAL):
            element = (_jsx(Suspense, { fallback: null, children: _jsx(UnathorizedLayout, {}) }));
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_AUTHORIZED):
            element = (_jsx(Suspense, { fallback: null, children: _jsx(RouteAuthCheckWrapper, { children: _jsx(AuthorizedLayout, {}) }) }));
            break;
        case (RouteNames.NON_ROUTABLE_LAYOUT_HOME):
        case (RouteNames.HOME):
            element = _jsx(Home, {});
            break;
        case (RouteNames.HOME_OVERVIEW):
            element = _jsx(Overview, {});
            break;
        case (RouteNames.OVERVIEW_WALLET_DETAILS):
            element = _jsx(OverviewWalletDetails, {});
            break;
        case (RouteNames.HOME_BALANCE):
            element = _jsx(PlatformBalance, {});
            break;
        case (RouteNames.NON_ROUTABLE_NOT_FOUND):
            element = (_jsx(ErrorComponent, { variant: 'not_found' }));
            break;
        case (RouteNames.SUPPORT):
            element = _jsx(Support, {});
            break;
        case (RouteNames.DEV_COMPONENTS):
            element = _jsx(DevComponents, {});
            break;
        default:
    }
    return element;
};
const injectElementsWithHandle = (routesConfig) => {
    const resultRoutes = deepmerge({}, routesConfig);
    for (let i = 0; i < resultRoutes.length; i += 1) {
        const route = resultRoutes[i];
        if (IsDefined(route.name)) {
            const element = getElementByRoute(route.name);
            if (element) {
                route.element = element;
            }
            const errorElement = getErrorElementByRoute(route.name);
            if (errorElement) {
                route.errorElement = errorElement;
            }
        }
        if (route.helpers) {
            route.handle = route.helpers;
        }
        if (route.children) {
            route.children = injectElementsWithHandle(route.children);
        }
    }
    return resultRoutes;
};
const router = createBrowserRouter(injectElementsWithHandle(routes));
export default router;
